import { AppProps } from 'next/app';

import GlobalStyle from '../styles/GlobalStyle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'swiper/swiper.min.css';

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <>
      <GlobalStyle />
      <Component {...pageProps} />
    </>
  );
}
