import { createGlobalStyle } from 'styled-components';

export const colors = {
  gray100: '#F8F9FA',
  gray200: '#D7DEE5',
  gray300: '#AEB6C1',
  gray400: '#8A96A4',
  gray500: '#728091',
  gray600: '#5A6C7F',
  gray700: '#3E4B59',
  gray800: '#1E252D',

  orange100: '#FDE9E6',
  orange200: '#FFAA8C',
  orange300: '#FF6D38',
  orange400: '#FA4C07',
  orange500: '#DE3D00',

  green100: '#B5FA07',
  green200: '#0FCC45',

  blue: '#009AE9',

  yellow: '#FAC507',

  red: '#EE093C',
};

export default createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  * {
    box-sizing: border-box;
    font-family: 'Sora', sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  img {
    pointer-events: none;
    user-select: none;
  }

  a {
    text-decoration:none;
  }

  li, ul {
    list-style:none;
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6, p{
    margin: 0;
  }
`;
